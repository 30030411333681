// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import alarmReducer from './slices/amsSlice';
import sensorGroupReducer from './slices/sensorGroupSlice';
import renamedSensorsReducer from './slices/renamedSensorsSlice';
import tagsReducer from './slices/tagsSlice';
import sensorLimitsReducer from './slices/sensorLimitsSlice';
import liveDataReducer from './slices/liveDataSlice';
import appConfigReducer from './slices/appConfig';
import sensorMultiplierReducer from './slices/sensorMultiplierSlicer';
import reloadReducer from './slices/reloadSlice';

const store = configureStore({
  reducer: {
    alarm: alarmReducer,
    sensorGroup: sensorGroupReducer,
    renamedSensors: renamedSensorsReducer,
    tags: tagsReducer,
    sensorLimits: sensorLimitsReducer,
    liveData: liveDataReducer,
    appConfig:appConfigReducer,
    sensorMultiplier: sensorMultiplierReducer,
    reload: reloadReducer,
  }
});

export default store;
