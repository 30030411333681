import Configurator from '../../config/Configurator.js';
import axios from 'axios';

var dataCache = {};

// Function to clear the cache
export function clearCache() {

    dataCache = {}

    console.log('Cache cleared.');
}

// Function to fetch old data with optional force reload
export async function fetchOldData(sensorNum, date, forceReload = false) {
    try {
        const dateObj = new Date(date);
        const currentDate = new Date();

        const year = dateObj.getUTCFullYear();
        const month = dateObj.getUTCMonth() + 1;
        let day = dateObj.getUTCDate();

        // Ensure the day is valid
        const lastDayOfMonth = new Date(year, month, 0).getUTCDate();
        day = Math.min(day, lastDayOfMonth);
        if (year === currentDate.getUTCFullYear() && month === currentDate.getUTCMonth() + 1) {
            day = Math.min(day, currentDate.getUTCDate());
        }

        const normalizedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const cacheKey = `${sensorNum}-${normalizedDate}`;

        // Initialize cache for sensorNum if it doesn't exist
        if (!dataCache[sensorNum]) {
            dataCache[sensorNum] = [];
        }

        // Check if data is already cached and decide on start date based on forceReload
        let startDate = `${normalizedDate}T00:00:00.000Z`; // Default start of the day
        const cachedItem = dataCache[sensorNum].find(item => item.key === cacheKey);

        if (cachedItem && forceReload) {
            const lastEntryTimestamp = cachedItem.data?.x?.[cachedItem.data.x.length - 2]; // Get the last timestamp
            if (lastEntryTimestamp) {
                startDate = new Date(lastEntryTimestamp).toISOString(); // Fetch data from the last known timestamp
            }
        } else if (cachedItem && !forceReload && cachedItem?.data?.x) {
            // Ensure boolean values are not converted back to integers
            if (cachedItem.data.isBoolean) {
                cachedItem.data.y = cachedItem.data.y.map(val => !!val);
            }
            return cachedItem.data;  // Return the cached data immediately
        }
        const endDate = `${normalizedDate}T23:59:59.000Z`;

        const requestBody = {
            serial: localStorage.getItem("serial"),
            tag: sensorNum,
            start: startDate,
            end: endDate
        };

        const config = {
            method: 'post',
            url: (await Configurator()).API_URL + `/v2/io/records/abstract/graph`,
            headers: {
                ...axios.defaults.headers.common,
                'Api-Key': localStorage.getItem("apiKey"),
                'User-Id': localStorage.getItem("userID"),
            },
            data: requestBody,
            maxBodyLength: Infinity,
        };

        const response = await axios.request(config)
            .then((r) => r.data)
            .catch((e) => {
                throw new Error(`HTTP error! Status: ${e}`);
            });

        // Detect if the data contains boolean values
        const isBoolean = typeof response?.data?.y?.[0] === "boolean";

        if (cachedItem && forceReload) {
            // Append new data to the existing cached data, preserving boolean flag
            cachedItem.data = {
                ...cachedItem.data,
                x: [...(cachedItem.data.x || []), ...(response.data.x || [])],
                y: [...(cachedItem.data.y || []), ...(response.data.y || [])].map(val => isBoolean ? !!val : val),
                isBoolean,  // Keep track of whether this sensor contains boolean data
            };

            response.data = cachedItem.data;
        } else {
            // Cache the data before returning and mark if boolean
            dataCache[sensorNum].push({
                key: cacheKey,
                data: {
                    ...response.data,
                    isBoolean
                }
            });
        }

        // Maintain a maximum of 3 cached items per sensor
        if (dataCache[sensorNum].length > 3) {
            dataCache[sensorNum].shift(); // Remove the oldest item
        }

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null; // Return null in case of an error
    }
}
