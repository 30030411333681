import React, { Children, useEffect, useState } from 'react';
import './Navbar.css';
import ecpIcon from '../../assets/logo192.png';
import mobileIcon from '../../assets/elecicon.webp';
import { useLocation } from 'react-router-dom';
import { navbarIcons } from '../config/navbar.icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarVisible } from '../../services/slices/appConfig';
import { isMobile } from 'react-device-detect';

export default function TopNavBar({ children }) {
    const dispatch = useDispatch();

    const isSidebarVisible = useSelector((state) => state.appConfig.isSidebarVisible);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation()?.pathname.replaceAll('/', '');

    const navbarClassName = `navbar-v3 ${location === 'configuration' ? 'navbar-config' : ''}`;

    useEffect(() => {
        const handleResize = () => {

            if (isMobile || window.innerWidth < 1100 || window.innerHeight < 500) {
                if (isSidebarVisible) {
                    dispatch(setSidebarVisible(false));
                }
            } else {
                if (!isSidebarVisible) {
                    dispatch(setSidebarVisible(true));
                }
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [isMobile]);

    const handleClickOutside = () => {
        if (isSidebarOpen) {
            hideSidebar();
        }
    };

    const hideSidebar = () => {
        dispatch(setSidebarVisible(false));
        setIsSidebarOpen(false);
    };

    const showSidebar = () => {
        dispatch(setSidebarVisible(true));
        setIsSidebarOpen(true);
    };

    return (
        <>
            <nav className={navbarClassName}>
                <div className='dashboard-icon' onClick={isSidebarOpen ? hideSidebar : showSidebar}>
                    {isSidebarOpen ? navbarIcons.closeIcon : navbarIcons.dashboardIcon}
                </div>
                <img className='ecp-icon' src={ecpIcon} alt="ECP Icon" />
                <img src={mobileIcon} alt="ECP Icon Mobile" className="ecp-icon-mobile" />

                {children}

            </nav>

            {isSidebarOpen && <div className="sidebar-overlay visible" onClick={handleClickOutside}></div>}
        </>
    );
}