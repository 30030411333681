// components/Sidebar.js
import React from 'react';
import './Sidebar.css';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { sidebarItems } from '../config/sidebar.items';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarVisible } from '../../services/slices/appConfig';
import { visibleModelsSerials } from '../config/visible.shipmodel.serials';

function Sidebar({ setIsLoggedIn }) {
  const dispatch = useDispatch();

  const isSidebarVisible = useSelector((state) => state.appConfig.isSidebarVisible);
  const sidebarClass = isSidebarVisible ? 'navbar navbar-open' : 'navbar navbar-closed';

  const userSerial = localStorage.getItem('serial');
  const isVisibleModel = visibleModelsSerials.includes(userSerial);

  const location = useLocation();
  const navbarClass = location.pathname.includes('simulator') ? `${sidebarClass} sidebar-simulator` : sidebarClass;

  return (
    <>
      {isSidebarVisible && <div className="sidebar-overlay visible" onClick={() => dispatch(setSidebarVisible(false))} />}
      <nav className={navbarClass}>
        <ul className="navbar-nav">
          {sidebarItems.map((item, index) => {
            if (item.path === '/simulator' && !isVisibleModel) {
              return null;
            }
            return (
              <li key={index} className="nav-item">
                <NavLink
                  to={item.path}
                  activeClassName="active"
                  className="nav-link"
                  onClick={item.onClick ? () => item.onClick(setIsLoggedIn) : null}
                >
                  {item.icon}
                  <span className="link-text">{item.text}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default React.memo(Sidebar);
