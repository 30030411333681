import React, { useState } from "react";
import "./SerialSelect.css";
import { Button, Menu, MenuItem, ListItemIcon, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";  // 'X' icon for delete

const SerialSelect = ({  desc = "", id = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  // options=[1,2,3,4,5,6,7,8,9,10]
  var options = JSON.parse(localStorage.getItem("serials")) || [];
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDelete = (option) => {
    console.log(options)
    // Filter out the selected option from the options array
    const updatedOptions = options.filter(item => item !== option);
   options=updatedOptions;
    console.log(updatedOptions);
    setSelectedOption("");
    // Update localStorage with the new options array
    localStorage.setItem("serials", JSON.stringify(updatedOptions));
   
  };


  return (
    <div id={id} className={`container`}>
      <input
        type="text"
        className="textbox"
        placeholder={desc}
        value={selectedOption}
        onChange={handleChange}
      />
      {options.length>0 &&
        <Button
          className="dropdown-btn"
          onClick={toggleDropdown}
          endIcon={<ArrowDropDownIcon
            sx={{ fontSize: '32px !important' }}

          />}

        >

        </Button>}
      {isOpen && (
        <div className="dropdown-content">
           {options.map((option, index) => (
             <MenuItem
             key={index}
             onClick={() => selectOption(option)}
             sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
           >
              {option}
              <IconButton
              className="serial-close-button"
              onClick={() => handleDelete(option)}
                sx={{ width:'fit-content !important',marginLeft: 'auto' }}  // Pushes the button to the far right
                aria-label="delete"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default SerialSelect;
